

































import { useActions, useState } from '@/shared/mixins/helpers'
import { defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import maturityInfo from '@/assets/data/control-assessment-data.json'
import Table from '@/shared/components/Table.vue'
import utils from '@/shared/mixins/utils'

export default defineComponent({
    components: { Table },
    setup() {
        const tableHeaders = ref([])
        const { t } = useI18n()
        const { language } = useState(['language'])
        const maturityLevelInfoList = ref([])
        const { SET_OPEN_MENU } = useActions(['SET_OPEN_MENU'])
        const { SET_LEFT_MENU_SIDEBAR } = useActions(['SET_LEFT_MENU_SIDEBAR'])
        const { menuOpen } = useState(['menuOpen'])
        const visibleLeft = ref(false)

        // get maturity data from assets
        const getMaturityLevelData = () => {
            maturityLevelInfoList.value = []
            const maturtyInfo = maturityInfo.MATURITY_LEVEL_STATIC_INFO_TABLEDATA
            // add language changes
            maturtyInfo.map((info) => {
                maturityLevelInfoList.value.push({
                    level: info.level,
                    name: t(info.name, language.value),
                    description: t(info.description, language.value),
                    criteria: t(info.criteria, language.value),
                })
            })
        }
        // set table headers
        const setTableHeaders = () => {
            tableHeaders.value = [
                {
                    header: t('MATURITY_STATIC_TABLE_COLUMN_LEVEL', language.value),
                    fieldName: 'level',
                    style: 'min-width: 150px;',
                },
                {
                    header: t('MATURITY_STATIC_TABLE_COLUMN_NAME', language.value),
                    fieldName: 'name',
                },
                {
                    header: t('MATURITY_STATIC_TABLE_COLUMN_DESCRIPTION', language.value),
                    fieldName: 'description',
                },
                {
                    header: t('MATURITY_STATIC_TABLE_COLUMN_CRITERIA', language.value),
                    fieldName: 'criteria',
                    htmlTags: true,
                },
            ]
        }

        // watch for language change from store and update table headers by calling setTableHeaders
        watch(
            language,
            () => {
                setTableHeaders()
            },
            {
                immediate: true,
            }
        )

        onMounted(() => {
            getMaturityLevelData()
        })

        return {
            t,
            language,
            maturityLevelInfoList,
            tableHeaders,
            utils,
            menuOpen,
            visibleLeft,
            SET_OPEN_MENU,
            SET_LEFT_MENU_SIDEBAR,
        }
    },
})
